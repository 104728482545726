<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Ticket
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="ticket-form">
          <v-select
            label="Customer *"
            v-model="fields.customer_id"
            :items="customers"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :disabled="isEditing"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          ></v-select>
          <v-select
            label="Time Block *"
            v-model="fields.time_block_id"
            v-if="isChristmas"
            :items="time_blocks"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('time_block_id')"
            :error-messages="errors['time_block_id']"
          ></v-select>
          <v-text-field
            label="Total Tickets *"
            v-model="fields.total_tickets"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_tickets')"
            :error-messages="errors['total_tickets']"
          ></v-text-field>
          <v-text-field
            label="Total Under 3 Tickets *"
            v-model="fields.total_tickets_under_3"
            v-if="!isChristmas"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_tickets_under_3')"
            :error-messages="errors['total_tickets_under_3']"
          ></v-text-field>
          <v-text-field
            label="Total Over 3 Tickets *"
            v-model="fields.total_tickets_over_3"
            v-if="!isChristmas"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_tickets_over_3')"
            :error-messages="errors['total_tickets_over_3']"
          ></v-text-field>
          <v-text-field
            label="Cost *"
            type="number"
            min="0.00"
            step="1.00"
            v-model="fields.cost"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('cost')"
            :error-messages="errors['cost']"
          ></v-text-field>
          <v-textarea
            label="Booking Information *"
            v-model="fields.booking_notes"
            v-if="isChristmas"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('booking_notes')"
            :error-messages="errors['booking_notes']"
          ></v-textarea>
          <v-switch
            inset
            color="green"
            v-model="fields.has_collected"
            label="Ticket Has Been Collected?"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="ticket-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    isChristmas: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      ticket: {},
      fields: {
        customer_id: null,
        cost: null,
        total_tickets: null,
        total_tickets_under_3: 0,
        total_tickets_over_3: 0,
        has_collected: false,
        time_block_id: null,
        booking_notes: null,
      },
      errors: {},
    };
  },

  methods: {
    open(ticket = null) {
      if (ticket !== null) {
        this.ticket = ticket;
        this.isEditing = true;
        this.fields.customer_id = ticket.customer.id;
        this.fields.total_tickets = ticket.total_tickets;
        this.fields.total_tickets_under_3 = ticket.total_tickets_under_3;
        this.fields.total_tickets_over_3 = ticket.total_tickets_over_3;
        this.fields.has_collected = ticket.has_collected;
        this.fields.cost = ticket.cost;
        this.fields.time_block_id = ticket.time_block_id;
        this.fields.booking_notes = ticket.booking_notes;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.ticket = {};
      this.fields = {
        customer_id: null,
        total_tickets: null,
        total_tickets_under_3: 0,
        total_tickets_over_3: 0,
        cost: null,
        has_collected: false,
        booking_notes: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        eventId: this.$route.params.eventId,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.ticketId = this.ticket.id;
      }

      this.$store
        .dispatch("craigtoun/eventsStore/saveTicket", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },

  computed: {
    customers() {
      return this.$store.getters["craigtoun/customersStore/all"];
    },

    time_blocks() {
      return this.event.event.time_blocks.map((time_block) => {
        return {
          text:
            time_block.formatted_dates.date +
            ": " +
            time_block.formatted_dates.start_time +
            " - " +
            time_block.formatted_dates.end_time,
          value: time_block.id,
        };
      });
    },
  },
};
</script>
